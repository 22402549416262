import {
  ButtonCopy,
  Container,
  Stack,
  Grid,
  GridItem,
  GRID_GAP,
  Modal,
} from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { Filters, useFilters, usePaymentMethodDefinition } from 'shared/hooks'
import { Resource, AccessLevel, RestrictAccess } from 'shared/permissions'
import { AdditionalIdentifiersPanel } from 'transactions/components/AdditionalIdentifiersPanel'
import { BuyerPanel } from 'transactions/components/BuyerPanel'
import { CartItemsPanel } from 'transactions/components/CartItemsPanel'
import { ConnectionResponsePanel } from 'transactions/components/ConnectionResponsePanel'
import { EventsPanel } from 'transactions/components/EventsPanel'
import { HistoryPanel } from 'transactions/components/HistoryPanel'
import { MetadataPanel } from 'transactions/components/MetadataPanel'
import {
  ModalTransactionCapture,
  ModalTransactionVoid,
  ModalTransactionRefund,
  ModalTransactionRefresh,
} from 'transactions/components/Modal'
import { PaymentMethodsPanel } from 'transactions/components/PaymentMethodsPanel/PaymentMethodsPanel'
import { ProcessorResponsePanel } from 'transactions/components/ProcessorResponsePanel'
import { RecurringPanel } from 'transactions/components/RecurringPanel'
import { RefundsPanel } from 'transactions/components/RefundsPanel'
import { SettlementsPanel } from 'transactions/components/SettlementsPanel'
import { ThreeDSecurePanel } from 'transactions/components/ThreeDSecurePanel'
import TransactionActions from 'transactions/components/TransactionActions'
import { TransactionFlowPanel } from 'transactions/components/TransactionFlowPanel'
import TransactionOverview from 'transactions/components/TransactionOverview'
import { TransactionPageLayout } from 'transactions/components/TransactionPageLayout'
import { getPaymentMethodKey } from 'transactions/helpers/get-payment-method'
import { useTransactionCapture } from 'transactions/hooks/use-transaction-capture'
import { useTransactionPaymentMethod } from 'transactions/hooks/use-transaction-payment-methods'
import { useTransactionRefresh } from 'transactions/hooks/use-transaction-refresh'
import { useTransactionRefund } from 'transactions/hooks/use-transaction-refund'
import { useTransactionVoid } from 'transactions/hooks/use-transaction-void'
import { PaymentMethod, Transaction } from 'transactions/services'

const { useModal } = Modal

interface TransactionDetailsViewProps {
  transaction?: Transaction
  paymentMethod?: PaymentMethod
  title: string
  titleChildren: ReactNode
  titleSubChildren: ReactNode
}

const TransactionDetailsPageView = ({
  transaction,
  paymentMethod,
}: TransactionDetailsViewProps) => {
  const [, setFilters] = useFilters<Filters & { paymentMethodKey: string }>()
  const { isSinglePaymentMethod } = useTransactionPaymentMethod({
    paymentMethod: transaction?.paymentMethod || null,
    giftCardRedemptions: transaction?.giftCardRedemptions ?? [],
  })
  const method = usePaymentMethodDefinition(transaction?.paymentMethod?.method)
  const isCardTransaction = method?.id === 'card' || method?.typeof === 'card'
  const { isModalOpen, openModal, closeModal } = useModal()
  const { onCapture, capturing } = useTransactionCapture(transaction, () => {
    closeModal('capture')
  })
  const { onVoid, voiding } = useTransactionVoid(transaction, () => {
    closeModal('void')
  })
  const { onRefund, refunding } = useTransactionRefund(transaction, () => {
    closeModal('refund')
  })
  const { onRefresh, refreshing } = useTransactionRefresh(transaction, () => {
    closeModal('refresh')
  })

  const getDefaultPaymentMethodKey = () => {
    if (transaction?.paymentMethod) {
      return getPaymentMethodKey(transaction?.paymentMethod)
    }

    if (!!transaction?.giftCardRedemptions.length) {
      const giftCardRedemption = transaction.giftCardRedemptions.find(
        (giftCardRedemption) =>
          giftCardRedemption.amount - giftCardRedemption.refundedAmount > 0
      )

      if (giftCardRedemption) {
        return getPaymentMethodKey(giftCardRedemption)
      }
    }

    return null
  }

  const onTransactionRefund = () => {
    const paymentMethodKey = getDefaultPaymentMethodKey()

    if (!isSinglePaymentMethod && paymentMethodKey) {
      setFilters({
        paymentMethodKey,
      })
    }

    openModal('refund')
  }

  return (
    <>
      {transaction && (
        <TransactionPageLayout
          transaction={transaction}
          actions={
            <>
              <ButtonCopy variant="secondary" copy={document.location.href}>
                Copy link
              </ButtonCopy>
              <RestrictAccess
                resource={Resource.transactions}
                accessLevel={AccessLevel.write}
              >
                <TransactionActions
                  transaction={transaction}
                  onCapture={() => openModal('capture')}
                  onVoid={() => openModal('void')}
                  onRefund={onTransactionRefund}
                  onRefresh={() => openModal('refresh')}
                />
              </RestrictAccess>
            </>
          }
        >
          <Stack gap={32}>
            <TransactionOverview
              transaction={transaction}
              paymentMethod={paymentMethod}
            />
            <Container>
              <Grid>
                <GridItem gridColumn="span 12">
                  <PaymentMethodsPanel
                    transaction={transaction}
                    paymentMethod={paymentMethod}
                  />
                </GridItem>
                <GridItem gridColumn="span 12">
                  <RefundsPanel
                    transaction={transaction}
                    paymentMethod={paymentMethod}
                  />
                </GridItem>
                {(transaction.buyer || transaction.shippingDetails) && (
                  <GridItem gridColumn="span 12">
                    <BuyerPanel data={transaction} />
                  </GridItem>
                )}
                <GridItem gridColumn="span 12">
                  <HistoryPanel transaction={transaction} />
                </GridItem>
                <GridItem gridColumn="span 12">
                  <EventsPanel
                    transaction={transaction}
                    paymentMethod={paymentMethod}
                  />
                </GridItem>
                {transaction.settled && (
                  <GridItem gridColumn="span 12">
                    <SettlementsPanel transaction={transaction} />
                  </GridItem>
                )}
                {(transaction.cartItems ?? []).length > 0 && (
                  <GridItem gridColumn="span 12">
                    <CartItemsPanel transaction={transaction} />
                  </GridItem>
                )}
                <GridItem
                  gridColumn={{ xs: 'span 12', lg: 'span 6' }}
                  gap={GRID_GAP}
                  as={Stack}
                >
                  {isCardTransaction ? (
                    <ProcessorResponsePanel transaction={transaction} />
                  ) : (
                    <ConnectionResponsePanel transaction={transaction} />
                  )}
                  <RecurringPanel transaction={transaction} />
                </GridItem>
                <GridItem
                  gridColumn={{ xs: 'span 12', lg: 'span 6' }}
                  gap={GRID_GAP}
                  as={Stack}
                >
                  {isCardTransaction && (
                    <ThreeDSecurePanel
                      threeDSecure={transaction?.threeDSecure}
                    />
                  )}
                  {transaction.metadata && (
                    <MetadataPanel metadata={transaction.metadata} />
                  )}
                  <AdditionalIdentifiersPanel
                    additionalIdentifiers={transaction.additionalIdentifiers}
                  />
                  <TransactionFlowPanel transaction={transaction} />
                </GridItem>
              </Grid>
            </Container>
          </Stack>

          {isModalOpen('capture') && (
            <ModalTransactionCapture
              transaction={transaction}
              loading={capturing}
              onCapture={onCapture}
              onClose={() => closeModal('capture')}
            />
          )}

          {isModalOpen('void') && (
            <ModalTransactionVoid
              transaction={transaction}
              loading={voiding}
              onVoid={onVoid}
              onClose={() => closeModal('void')}
            />
          )}

          {isModalOpen('refund') && (
            <ModalTransactionRefund
              transaction={transaction}
              loading={refunding}
              onRefund={onRefund}
              onClose={() => closeModal('refund')}
            />
          )}
          {isModalOpen('refresh') && (
            <ModalTransactionRefresh
              transaction={transaction}
              loading={refreshing}
              onRefresh={onRefresh}
              onClose={() => closeModal('refresh')}
            />
          )}
        </TransactionPageLayout>
      )}
    </>
  )
}

export default TransactionDetailsPageView
