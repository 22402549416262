import { compact, isEmpty } from 'lodash'
import { Buyer } from 'buyers/services/buyers'

export const getBuyerName = (buyer?: Buyer) => {
  if (buyer?.billingDetails?.firstName && buyer?.billingDetails?.lastName) {
    return `${buyer.billingDetails?.firstName} ${buyer.billingDetails?.lastName}`
  }

  if (buyer?.displayName) {
    return buyer?.displayName
  }

  return 'No name'
}

export const getBillingAddress = (buyer?: Buyer) => {
  if (!buyer?.billingDetails?.address) {
    return [null, null]
  }

  const line1 = compact([
    buyer.billingDetails.address.line1,
    buyer.billingDetails.address.line2,
  ]).join(', ')

  const line2 = compact([
    buyer.billingDetails.address.city,
    buyer.billingDetails.address.state,
    buyer.billingDetails.address.country,
  ]).join(', ')

  if (isEmpty(line1) && isEmpty(line2)) {
    return [null, null]
  }

  return [line1, line2]
}
