import {
  ButtonLink,
  Flex,
  Description,
  Tooltip,
  Box,
} from '@gr4vy/poutine-react'
import { compact } from 'lodash'
import { useParams } from 'react-router-dom'
import { usePaymentServiceDefinitions } from 'connections'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import {
  DataTable,
  ColumnDef,
  TimeDate,
  DataTableProps,
} from 'shared/components/DataTable'
import { displayTransactionExternalIdentifier } from 'shared/constants/merchant'
import { is } from 'shared/helpers/is'
import { pathTo } from 'shared/paths/transactions'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { QueryResult } from 'shared/services/client'
import { PaymentMethodsTag } from 'transactions/components/PaymentMethodsTag'
import { TransactionAmount } from 'transactions/components/TransactionAmount'
import TransactionAmountFilter from 'transactions/components/TransactionAmountFilter'
import TransactionConnectionFilter from 'transactions/components/TransactionConnectionFilter'
import TransactionDateTimeFilter from 'transactions/components/TransactionDateTimeFilter'
import TransactionPaymentMethodFilter from 'transactions/components/TransactionPaymentMethodFilter'
import { TransactionStatus } from 'transactions/components/TransactionStatus'
import TransactionStatusFilter from 'transactions/components/TransactionStatusFilter'
import { TransactionTableColumnId } from 'transactions/constants/table'
import { TransactionSummary, PayoutSummary } from 'transactions/services'
import { PaymentMethodDescription } from '../PaymentMethodDescription'
import styles from './TransactionTable.module.scss'

export type Summary = TransactionSummary | PayoutSummary

export type TransactionTableProps = Pick<
  DataTableProps<Summary>,
  'data' | 'pagination' | 'loading' | 'columns'
> & { page: QueryResult<Summary> } & {
  disableColumnFilters?: boolean
  resource?: string
}

export const getTransactionColumns: (
  disableColumnFilters?: boolean
) => Array<ColumnDef<Summary>> = (disableColumnFilters) => [
  {
    accessorKey: 'status',
    size: 192,
    cell: ({ row }) => {
      return <TransactionStatus transaction={row.original} />
    },
    header: () => (
      <Box className={styles.filter}>
        {disableColumnFilters ? (
          'Status'
        ) : (
          <TransactionStatusFilter label="Status" />
        )}
      </Box>
    ),
  },
  {
    accessorKey: 'amount',
    size: 128,
    cell: ({ row }) => {
      return <TransactionAmount transaction={row.original} marginRight={24} />
    },
    header: () => (
      <Box className={[styles.filter, styles.amount]}>
        {disableColumnFilters ? 'Amount' : <TransactionAmountFilter />}
      </Box>
    ),
  },
  {
    id: TransactionTableColumnId.PAYMENT_METHOD,
    accessorKey: TransactionTableColumnId.PAYMENT_METHOD,
    size: 250,
    cell: function PaymentMethodCell({ row }) {
      const giftCardRedemptions = row.original.giftCardRedemptions
      const paymentMethod = row.original.paymentMethod

      return (
        <Flex alignItems="center">
          <Box minWidth={0}>
            <PaymentMethodDescription
              paymentMethod={paymentMethod}
              giftCardRedemptions={giftCardRedemptions}
            />
          </Box>
          <PaymentMethodsTag
            paymentMethod={paymentMethod}
            giftCardRedemptions={giftCardRedemptions}
          />
        </Flex>
      )
    },
    header: () => (
      <Box className={styles.filter}>
        {disableColumnFilters ? (
          'Payment Method'
        ) : (
          <TransactionPaymentMethodFilter label="Payment Method" />
        )}
      </Box>
    ),
  },
  {
    accessorKey: 'paymentService',
    id: TransactionTableColumnId.CONNECTION,
    size: 250,
    cell: function ConnectionCell({ getValue, row }) {
      const transaction = row.original
      const { merchantAccountId } = useParams()
      const paymentService = getValue<Summary['paymentService']>()
      const { paymentServiceDefinitions } = usePaymentServiceDefinitions()
      const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
        merchantAccountId,
        transaction.giftCardService?.giftCardServiceDefinitionId,
        {
          enabled:
            !!transaction.giftCardService?.giftCardServiceDefinitionId &&
            !transaction.paymentMethod,
        }
      )
      const paymentServiceDefinition =
        paymentService?.paymentServiceDefinitionId
          ? paymentServiceDefinitions[paymentService.paymentServiceDefinitionId]
          : null

      const getDisplayName = () => {
        if (!transaction.paymentMethod) {
          return giftCardServiceDefinition?.displayName
        }

        return paymentService?.displayName &&
          !is.emptyString(paymentServiceDefinition?.displayName)
          ? paymentService.displayName
          : null
      }

      const displayName = getDisplayName()
      const iconUrl = transaction.paymentMethod
        ? paymentServiceDefinition?.iconUrl
        : giftCardServiceDefinition?.iconUrl

      return (
        <Description>
          <Description.Icon size={24} src={iconUrl} />
          {!!displayName && <Description.Text>{displayName}</Description.Text>}
          {!!transaction.externalIdentifier &&
            displayTransactionExternalIdentifier && (
              <Description.SubText>
                {transaction.externalIdentifier}
              </Description.SubText>
            )}
        </Description>
      )
    },
    header: () => (
      <Box className={styles.filter}>
        {disableColumnFilters ? (
          'Connection'
        ) : (
          <TransactionConnectionFilter label="Connection" />
        )}
      </Box>
    ),
  },
  {
    accessorKey: TransactionTableColumnId.BUYER,
    id: TransactionTableColumnId.BUYER,
    size: 210,
    cell: function BuyerCell({ row }) {
      const { merchantAccountId } = useParams() as {
        merchantAccountId: string
      }
      const [canReadBuyerBillingDetails] = useResourcePermissions(
        [Resource.buyersBillingDetails],
        AccessLevel.read
      )

      // Simplify lookups
      const transaction = row.original
      const buyer = transaction?.buyer
      const billingDetails = buyer?.billingDetails

      // Check a few things before we get started
      const hasBuyer = !!buyer
      const hasFullName =
        canReadBuyerBillingDetails &&
        (billingDetails?.firstName || billingDetails?.lastName)
      const hasLink = transaction?.id && hasBuyer

      // Try to build a full name from the billing details
      const fullName = hasFullName
        ? compact([billingDetails?.firstName, billingDetails?.lastName])
            .join(' ')
            .trim()
        : null

      // Determine the fields to display
      const email = canReadBuyerBillingDetails
        ? billingDetails?.emailAddress
        : null
      const displayName = buyer?.displayName || fullName
      const path = pathTo[transaction.type](
        merchantAccountId,
        transaction.id,
        'buyer'
      )

      // The final place where we calculate what to show. We show the name or external
      // identifier on line 1. If not present we show a dash.
      const line1 = displayName || buyer?.externalIdentifier || '\u2013'
      // Next, we show the email, or the external ID, unless already shown in line 1.
      const line2 = email || (displayName ? buyer?.externalIdentifier : null)

      if (hasLink) {
        return (
          <Description>
            <Description.Link href={path}>{line1}</Description.Link>
            {line2 ? <Description.SubText>{line2}</Description.SubText> : <></>}
          </Description>
        )
      }

      return (
        <Description>
          <Description.Text>{line1}</Description.Text>
          {line2 ? <Description.SubText>{line2}</Description.SubText> : <></>}
        </Description>
      )
    },
    header: 'Buyer',
  },
  {
    accessorKey: 'createdAt',
    size: 128,
    cell: function DatetimeCell({ getValue }) {
      const createdAt = getValue<Summary['createdAt']>()
      return <TimeDate value={createdAt} />
    },
    header: () => (
      <Box className={styles.filter}>
        {disableColumnFilters ? (
          'Date/Time'
        ) : (
          <TransactionDateTimeFilter label="Date/Time" />
        )}
      </Box>
    ),
  },
  {
    id: TransactionTableColumnId.ACTIONS,
    header: '',
    size: 64,
    cell: function ActionsCell({ row }) {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }
      const transaction = row.original
      return transaction ? (
        <Flex justifyContent="flex-end">
          <Tooltip content={`Go to ${transaction.type}`}>
            <ButtonLink
              variant="tertiary"
              size="small"
              href={pathTo[transaction.type](
                merchantAccountId,
                transaction?.id
              )}
            />
          </Tooltip>
        </Flex>
      ) : null
    },
  },
]

const TransactionTable = ({
  page,
  pagination,
  columns,
  disableColumnFilters = false,
  resource = 'transactions',
}: TransactionTableProps) => {
  const { data, isLoading: loading } = page

  return (
    <DataTable
      data={data}
      pagination={pagination}
      columns={columns ?? getTransactionColumns(disableColumnFilters)}
      loading={loading}
    >
      <DataTable.Empty>No {resource} to show.</DataTable.Empty>
      <DataTable.Loading>Loading...</DataTable.Loading>
    </DataTable>
  )
}

export default TransactionTable
