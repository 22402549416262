import { Heading } from '@gr4vy/poutine-react'
import { startCase } from 'lodash'
import { Fragment } from 'react'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'

function AdditionalIdentifiersPanel({
  additionalIdentifiers,
}: {
  additionalIdentifiers: { [key: string]: string | null }
}) {
  const otherIds = Object.keys(additionalIdentifiers)
    .filter(
      (id) =>
        ![
          'paymentServiceAuthorizationId',
          'paymentServiceCaptureId',
          'paymentServiceProcessorId',
        ].includes(id)
    )
    .sort()

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Additional Identifiers</Heading>
      </Panel.Header>
      <Panel.Content>
        <Summary inline>
          <Summary.Key>Authorization ID</Summary.Key>
          <Summary.Value>
            {additionalIdentifiers['paymentServiceAuthorizationId']}
          </Summary.Value>
        </Summary>
        <Summary inline>
          <Summary.Key>Capture ID</Summary.Key>
          <Summary.Value>
            {additionalIdentifiers['paymentServiceCaptureId']}
          </Summary.Value>
        </Summary>
        <Summary inline>
          <Summary.Key>Processor ID</Summary.Key>
          <Summary.Value>
            {additionalIdentifiers['paymentServiceProcessorId']}
          </Summary.Value>
        </Summary>
        <Summary inline>
          {otherIds.map((key: string) => (
            <Fragment key={key}>
              <Summary.Key>{startCase(key)}</Summary.Key>
              <Summary.Value>{additionalIdentifiers[key]}</Summary.Value>
            </Fragment>
          ))}
        </Summary>
      </Panel.Content>
    </Panel>
  )
}

export default AdditionalIdentifiersPanel
