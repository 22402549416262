import { useState, useEffect, useCallback } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { is } from 'shared/helpers/is'
import { getItem, setItem } from 'shared/helpers/localstorage'
import { serialize, deserialize } from 'shared/helpers/serialize'
import { HOME_PATH } from 'shared/paths/home'

export interface Filters {
  limit?: number
}

const DEFAULT_FILTERS = {
  limit: 20,
}

export const LIMIT_KEY = 'gr4vy-filters-limit'
export const EXCLUDED_PATHS = ['/home']

export const useFilters = <T extends Filters>(customFilters?: Partial<T>) => {
  const location = useLocation()
  const navigate = useNavigate()

  DEFAULT_FILTERS.limit = getItem(LIMIT_KEY, DEFAULT_FILTERS.limit)

  const [filters, setStateFilters] = useState<Partial<T>>({
    ...DEFAULT_FILTERS,
    ...customFilters,
  } as Partial<T>)

  const isHomePath = useCallback((pathname: string) => {
    return matchPath(
      {
        path: HOME_PATH,
        end: false,
      },
      pathname
    )
  }, [])

  const applyFilters = useCallback(
    ({
      search,
      filters = {},
    }: {
      search: string
      filters?: Parameters<typeof setStateFilters>[0]
    }) => {
      const newFilters = !is.emptyObject(filters)
        ? filters
        : { ...DEFAULT_FILTERS, ...customFilters }
      setStateFilters({ ...newFilters, ...deserialize(search) })
    },
    [customFilters]
  )

  const setFilters = (newFilters: Parameters<typeof setStateFilters>[0]) => {
    navigate({ search: serialize(newFilters) })
    applyFilters({ search: serialize(newFilters), filters })
  }

  useEffect(() => {
    const shouldPersistLimit = !isHomePath(location.pathname) && filters.limit

    if (shouldPersistLimit) {
      setItem(LIMIT_KEY, filters.limit)
    }
  }, [filters.limit, isHomePath, location.pathname])

  useEffect(() => {
    applyFilters(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return [filters, setFilters] as const
}
