import { useInfiniteQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Connection } from 'home/components/ConnectionsTable/ConnectionsTable'
import { HomeFilters } from 'home/constants/filter'
import {
  getHealthDashboardConnections,
  getHealthDashboardConnectionsVolume,
  Period,
} from 'home/services/health-dashboard'
import { CONNECTIONS_TRANSACTIONS, CONNECTIONS_VOLUME } from 'shared/constants'
import { CollectionResponse } from 'shared/services/client'

interface HealthDashboardConnectionsProps {
  filters: Partial<HomeFilters>
  enabled: boolean
}

export const useHealthDashboardConnections = ({
  filters,
  enabled,
}: HealthDashboardConnectionsProps): Pick<
  UseQueryResult<CollectionResponse<Connection>, AxiosError>,
  'data' | 'isLoading'
> => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const isVolume = filters.filterBy === 'volume'

  const {
    isLoading,
    isFetching,
    isSuccess,
    data: connections,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery<CollectionResponse<Connection>, AxiosError>({
    queryKey: [
      isVolume ? CONNECTIONS_VOLUME : CONNECTIONS_TRANSACTIONS,
      merchantAccountId,
      filters.period,
      filters.currency,
    ],
    queryFn: ({ pageParam }) =>
      (isVolume
        ? getHealthDashboardConnectionsVolume
        : getHealthDashboardConnections)({
        ...(isVolume && {
          currency: filters.currency as Uppercase<string>,
        }),
        period: filters.period as Period,
        limit: 100,
        cursor: pageParam as string | null,
      }),
    enabled: enabled && !!filters.period,
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    meta: {
      error: {
        ignore: [404],
      },
    },
  })

  const allPagesData =
    connections?.pages.map((page) => [...page.items]).flat() || []

  const data = { items: allPagesData, limit: 100 }

  useEffect(() => {
    if (!isFetching && isSuccess && hasNextPage) {
      fetchNextPage({ cancelRefetch: false })
    }
  }, [isFetching, isSuccess, hasNextPage, fetchNextPage])

  return { isLoading, data }
}
