import {
  Tooltip,
  Flex,
  TextLine,
  ButtonLink,
  Description,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { Buyer } from 'buyers/services/buyers'
import { getBuyerName, getBillingAddress } from 'buyers/utils'
import {
  ColumnDef,
  DataTable,
  TimeDate,
  DataTableProps,
} from 'shared/components/DataTable'
import { pathTo } from 'shared/paths/buyers'
import { QueryResult } from 'shared/services/client'

export type BuyersTableProps = Pick<
  DataTableProps<Buyer>,
  'pagination' | 'columns'
> & { page: QueryResult<Buyer> }

export const buyersColumns: Array<ColumnDef<Buyer>> = [
  {
    header: 'Name',
    accessorKey: 'displayName',
    size: 200,
    cell: function ActionsCell({ row }) {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }
      const buyer = row.original
      return (
        <Description>
          <Description.Link href={pathTo.buyer(merchantAccountId, buyer?.id)}>
            {getBuyerName(buyer)}
          </Description.Link>
          <Description.SubText>
            {row.original?.billingDetails?.emailAddress || '\u2013'}
          </Description.SubText>
        </Description>
      )
    },
  },
  {
    header: 'External identifier',
    accessorKey: 'externalIdentifier',
    size: 180,
    cell: ({ row }) => {
      const externalIdentifier = row?.original?.externalIdentifier || '\u2013'
      return <TextLine>{externalIdentifier}</TextLine>
    },
  },
  {
    header: 'Phone number',
    accessorKey: 'phoneNumber',
    size: 200,
    cell: ({ row }) => {
      const phoneNumber = row?.original?.billingDetails?.phoneNumber || '\u2013'
      return <TextLine>{phoneNumber}</TextLine>
    },
  },
  {
    header: 'Billing address',
    accessorKey: 'billingAddress',
    size: 246,
    cell: ({ row }) => {
      const buyer = row?.original
      const [line1, line2] = getBillingAddress(buyer)
      return line1 && line2 ? (
        <Description>
          <Description.Text>{line1}</Description.Text>
          <Description.SubText>{line2}</Description.SubText>
        </Description>
      ) : (
        <>&ndash;</>
      )
    },
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    size: 108,
    cell: ({ getValue }) => {
      const createdAt = getValue<Buyer['createdAt']>()
      return <TimeDate value={createdAt} />
    },
  },
  {
    header: 'Updated',
    accessorKey: 'updatedAt',
    size: 108,
    cell: ({ getValue }) => {
      const updatedAt = getValue<Buyer['updatedAt']>()
      return <TimeDate value={updatedAt} />
    },
  },
  {
    id: 'actions',
    header: '',
    size: 64,
    cell: function ActionsCell({ row }) {
      const buyer = row.original
      const { merchantAccountId } = useParams() as { merchantAccountId: string }
      return buyer ? (
        <Flex justifyContent="flex-end">
          <Tooltip content="Go to buyer">
            <ButtonLink
              variant="tertiary"
              size="small"
              href={pathTo.buyer(merchantAccountId, buyer?.id)}
            />
          </Tooltip>
        </Flex>
      ) : null
    },
  },
]

const BuyersTable = ({ page, pagination, columns }: BuyersTableProps) => {
  const { data, isLoading: loading } = page

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns ?? buyersColumns}
      pagination={pagination}
    />
  )
}

export default BuyersTable
