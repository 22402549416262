import client, { CollectionResponse } from 'shared/services/client'

export interface HealthDashboardStatus {
  currencies: string[] | undefined
  latestExecution:
    | {
        timestamp: string
      }
    | undefined
}

export type Period = '1-day' | '7-days' | '30-days'

export interface HealthDashboardTotalParams {
  period: Period
  currency?: Uppercase<string>
  limit?: number
  cursor?: string | null
}

export interface HealthDashboardVolumeSummary {
  transactionsAuthorized: number
  transactionsAuthorizedDeltaPct: number | null
  transactionsAuthorizedRate: number | null
  transactionsAuthorizedRateDelta: number | null
  refunds: number
  refundsDeltaPct: number | null
}

export interface HealthDashboardTransactionsSummary {
  transactionsAccepted: number
  transactionsAcceptedDeltaPct: number | null
  transactionsAuthorizedRate: number | null
  transactionsAuthorizedRateDelta: number | null
  refunds: number
  refundsDeltaPct: number | null
}

export interface HealthDashboardSeriesData {
  firstInterval: string
  transactionsAuthorizedRate: number[] | null[]
  refunds: number[]
}

export interface HealthDashboardSeriesVolumeData
  extends HealthDashboardSeriesData {
  transactionsAuthorized: number[]
}

export interface HealthDashboardSeriesTransactionsData
  extends HealthDashboardSeriesData {
  transactionsAccepted: number[]
}

export interface HealthDashboardVolumeSeries {
  current: HealthDashboardSeriesVolumeData
  previous: HealthDashboardSeriesVolumeData
}
export interface HealthDashboardTransactionsSeries {
  current: HealthDashboardSeriesTransactionsData
  previous: HealthDashboardSeriesTransactionsData
}
export interface HealthDashboardTotalVolume {
  summary: HealthDashboardVolumeSummary
  series: HealthDashboardVolumeSeries
}

export interface HealthDashboardTotalTransactions {
  summary: HealthDashboardTransactionsSummary
  series: HealthDashboardTransactionsSeries
}

export interface HealthDashboardConnectionSummary {
  paymentServiceId: string
  paymentServiceDefinitionId: string
  paymentServiceDisplayName: string
  paymentServiceIsActive: boolean
  paymentServiceIsDeleted: boolean
  transactionsAuthorized: number
  transactionsAuthorizedRate: number | null
  transactionsAuthorizedRateDelta: number | null
  transactionsDeclined: number
  transactionsRecovered: number
}

export interface HealthDashboardConnectionVolume {
  type: 'health-dashboard.connection-volume'
  summary: HealthDashboardConnectionSummary
}

export interface HealthDashboardConnection {
  type: 'health-dashboard.connection'
  summary: HealthDashboardConnectionSummary
}

export const getHealthDashboardConnections = async (
  params: HealthDashboardTotalParams
) => {
  const response = await client.get<
    CollectionResponse<HealthDashboardConnection>
  >('/health-dashboard/connections', { params })

  return response.data
}

export const getHealthDashboardConnectionsVolume = async (
  params: HealthDashboardTotalParams
) => {
  const response = await client.get<
    CollectionResponse<HealthDashboardConnectionVolume>
  >('/health-dashboard/connections-volume', { params })

  return response.data
}

export const getHealthDashboardStatus = async () => {
  const response = await client.get<HealthDashboardStatus>(
    '/health-dashboard/status'
  )

  return response.data
}

export const getHealthDashboardTotalVolume = async (
  params: HealthDashboardTotalParams
) => {
  const response = await client.get<HealthDashboardTotalVolume>(
    '/health-dashboard/total-volume',
    {
      params,
    }
  )

  return response.data
}

export const getHealthDashboardTotalTransactions = async (
  params: Pick<HealthDashboardTotalParams, 'period'>
) => {
  const response = await client.get<HealthDashboardTotalTransactions>(
    '/health-dashboard/total',
    {
      params,
    }
  )

  return response.data
}
